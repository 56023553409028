
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class PointPage extends Vue {
  @Prop() readonly isShowAlertPoint!: boolean;

  @Prop() readonly dataPoint!: object;

  @Prop() readonly dataStatus!: object;

  @Prop() readonly lang!: string;

  @Prop() readonly closeStoreModal: Function;
}
