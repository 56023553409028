
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class Auto extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  @Prop() readonly token!: string;

  isBonus = false;

  name = '';

  phoneNumber = ''

  async created() {
    const trueWallet = await this.DepositModule.fetchTrue();
    if (trueWallet.status.code === 0) {
      this.name = trueWallet.data.name;
      this.phoneNumber = trueWallet.data.phoneNumber;
    }
  }

  switchBonus() {
    const { isBonus } = this;
    this.DepositModule.setAutoBonus({
      isBonus,
    });
  }
}
