export const ENV = 'dev';
export const GENERIC_URL = {
  local: {
    api: 'http://10.30.0.5:6699',
    socket: 'https://skt-sit.ambslot-api.com/',
    result: 'https://api-staging.ambprovider-hub.com/',
  },
  dev: {
    api: 'https://sit.ambslot-api.com/',
    socket: 'https://skt-sit.ambslot-api.com/',
    result: 'https://api-staging.ambprovider-hub.com/',
  },
  uat: {
    api: 'https://uat.ambslot-api.com/',
    socket: 'https://skt-uat.ambslot-api.com/',
    result: 'https://api-staging.ambprovider-hub.com/',
  },
  prod: {
    api: 'https://prod.ambslot-api.com/',
    socket: 'wss://skt-prod.ambslot-api.com/',
    result: 'https://api-prod.ambprovider-hub.com/',
  },
};

export const BASE_URL = GENERIC_URL[ENV].api;
export const SOCKET_URL = GENERIC_URL[ENV].socket;
export const RESULT_SPADE_GAMING_URL = GENERIC_URL[ENV].result;
export const M_GET = 'GET';
export const M_POST = 'POST';
export const M_PUT = 'PUT';
export const M_DELETE = 'DELETE';

// export const FETCH_HISTORY = 'history/date';
// export const GET_HISTORY = 'history/get';
export const FETCH_HISTORY = 'history/date/lobby';
export const GET_HISTORY = 'history/period';
export const FETCH_HISTORY_POINT = 'history/points';

export const FETCH_RANKING = 'angpow/fetch/ranking';
export const FETCH_AUTO_PROFILE = 'auto/profile/v2';
export const FETCH_AUTO_INFO = 'auto/deposit/info';
export const SET_AUTO_BONUS = 'auto/setting/bonus';
export const CHECK_AUTO_FLOAT = 'auto/checkbankfloat';
export const CREATE_TRANSECTION = 'auto/createtransaction';
export const FETCH_TRUE = 'auto/truewallet';
export const GET_MIN_MAX = 'auto/getminmax';
export const GET_BACK_LINK = 'setting/getbacklink';
export const GET_QR = 'auto/initpayment';
export const GET_VERSION = 'version/fetch/lobby';
export const VERIFY_PAYMENT = 'auto/check';
export const FETCH_VERSION_NEW_LOBBY = 'auth/fetch/version';
export const GET_EVENT_DETAIL = 'angpow/fetch/event';
export const SENT_AUTO_WITHDRAW = 'auto/withdraw';
export const CHECK_WITHDRAW = 'auto/withdraw/check';
export const FETCH_CASHBACK = 'auto/checkcashback';
export const FETCH_DETAIL_CASHBACK = 'auto/cashbackdetail';
export const GET_CASHBACK = 'auto/cashback';

export const GET_RESULT = 'spadegaming/history';

export const FETCH_ITEM = 'angpow/fetch/gift';
export const ACTIVE_ITEM = 'angpow/result/quest';

export const FETCH_PROFILE = 'user/profile/v2';
export const FETCH_POINT = 'angpow/store/fetch';
export const ACTIVE_POINT = 'angpow/store/points/use';
