import {
  VuexModule, Module, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_PROFILE,
} from '@/utils/api';

@Module({ name: 'Profile' })
export default class Profile extends VuexModule {
  @Action
  fetchProfile() {
    return Vue.axios({
      url: BASE_URL + FETCH_PROFILE,
      data: {},
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }
}
