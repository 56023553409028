
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RankingInstance from '@/store/modules/Ranking';

@Component
export default class WinLose extends Vue {
  lists: any = [];

  header = '';

  mode: any = '';

  RankingModule = getModule(RankingInstance, this.$store);

  @Watch('this.$route.query.type', { immediate: true, deep: true })
  handleType() {
    if (this.$route.query.type === 'level') {
      this.header = 'ranking.top_level';
    } else if (this.$route.query.type === 'turnover') {
      this.header = 'ranking.most_turn_over';
    } else if (this.$route.query.type === 'multiplier') {
      this.header = 'ranking.high_winning';
    } else if (this.$route.query.type === 'winlose') {
      this.header = 'ranking.most_winning';
    }
  }

  async created() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.$route.query.t}`;
    this.mode = this.$route.query.mode;
    const params = {
      key: this.$route.query.type,
    };
    const rankList = await this.RankingModule.fetchRanking(params);
    this.lists = rankList;
  }
}
