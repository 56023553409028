import Vue from 'vue';
import Vuex from 'vuex';
import GeneralControl from '@/store/modules/GeneralControl';
import History from '@/store/modules/History';
import Ranking from '@/store/modules/Ranking';
import Deposit from '@/store/modules/Deposit';
import Cashback from '@/store/modules/Cashback';
import Result from '@/store/modules/Result';
import Item from '@/store/modules/Item';
import Point from '@/store/modules/Point';
import Profile from '@/store/modules/Profile';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    GeneralControl,
    History,
    Ranking,
    Deposit,
    Cashback,
    Result,
    Item,
    Point,
    Profile,
  },
});
