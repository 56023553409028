import '@fortawesome/fontawesome-free/css/all.min.css';
import Vue from 'vue';
import { VLazyImagePlugin } from 'v-lazy-image';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import Clipboard from 'v-clipboard';
import moment from 'moment-timezone';
import vuescroll from 'vuescroll';
import VueCurrencyFilter from 'vue-currency-filter';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VLazyImagePlugin);

Vue.use(vueAwesomeCountdown, 'vac');

Vue.use(Clipboard);

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

Vue.use(VueAxios, axios);

Vue.use(VueMoment, {
  moment,
});

Vue.use(VueCurrencyFilter, {
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
});

Vue.config.productionTip = false;

Vue.use(vuescroll, {
  ops: {
    // The global config
    bar: {
      keepShow: true,
    },
  },
  name: 'vueScroll',
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
