
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Inventory extends Vue {
  @Prop() readonly isSelectItem!: boolean;

  @Prop() readonly lang: string;

  @Prop() readonly showItem!: any;

  @Prop() readonly closeItemPopup!: Function;

  @Prop() readonly activeItem!: Function;

  handleActive(item: string) {
    this.activeItem(item);
  }
}
