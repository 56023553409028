
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';
import GeneralControl from '@/store/modules/GeneralControl';
import HistoryInstance from '@/store/modules/History';

@Component
export default class Withdraw extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  @Prop() readonly reciveBonus!: boolean;

  @Prop() readonly mode!: string;

  @Prop() readonly token!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code: any

  error = '';

  success = ''

  isBonus = false;

  minimumWithdraw = '';

  withdrawStatus = false;

  statusSuccess = false;

  mounted() {
    this.CheckWithdraw();
  }

  created() {
    this.isBonus = this.reciveBonus;
  }

  async CheckWithdraw() {
    const res = await this.DepositModule.checkWithdraw();
    if (res.status.code === 0) {
      this.withdrawStatus = res.data.disableBtn;
      this.minimumWithdraw = res.data.message;
    } else {
      this.error = res.status.message;
    }
  }

  async withdraw() {
    if (!this.withdrawStatus) {
      const params = {
        username: this.DepositModule.refillProfile.username,
        agentId: this.DepositModule.refillProfile.source,
      };
      this.withdrawStatus = true;
      const res = await this.DepositModule.reqeustWithdraw(params);
      this.code = res.code;
      if (res.status.code === 0) {
        this.statusSuccess = true;
        setTimeout(async () => {
          const version = await this.GeneralControlModule.getVersion();
          const url = version[this.mode];
          window.location.href = `${url}?t=${this.token}`;
        }, 2000);
      } else {
        this.withdrawStatus = false;
        this.error = res.status.message;
      }
    }
  }
}
