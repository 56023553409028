var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper flex-column justify-content-center",class:{ '-hide': !_vm.GeneralControlModule.depositModalState }},[_c('div',{staticClass:"scale-wrapper-deposit"},[_c('div',{staticClass:"wrapper-modal d-flex"},[(_vm.closeDeposit)?_c('div',{staticClass:"inner d-flex flex-column"},[_c('div',{staticClass:"heading d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"heading-box"},[_vm._v(" "+_vm._s(_vm.$t(_vm.modalList.find((item) => item.mode === _vm.currentModal).text))+" ")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"deposit-box"},[(_vm.currentModal === 'auto')?_c('Auto',{attrs:{"token":_vm.token}}):_vm._e(),(_vm.currentModal === 'float')?_c('Float',{attrs:{"token":_vm.token}}):_vm._e(),(_vm.currentModal === 'true')?_c('True'):_vm._e(),(_vm.currentModal === 'qr')?_c('QR'):_vm._e(),(_vm.currentModal === 'withdraw')?_c('Withdraw',{attrs:{"token":_vm.token,"mode":_vm.mode}}):_vm._e(),(_vm.currentModal === 'cashback')?_c('Cashback'):_vm._e()],1)])]):_vm._e()]),_c('div',{class:_vm.closeDeposit ? 'tool-list' : 'tool-list-nondeposit'},[_c('div',{staticClass:"close-modal d-flex flex-column align-items-center"},[_c('div',{staticClass:"warpper-btn",on:{"click":_vm.closeModal}},[_c('img',{staticClass:"action-btn",attrs:{"src":require(
              `@/assets/images/PGSlot_Lobby_UI/horizontal/Ambslot_Lobby_Button_Close_Icon.png`
            ),"alt":""}})]),_vm._v(" "+_vm._s(_vm.$t('deposit.close'))+" ")]),_c('div',[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"warpper-btn",on:{"click":_vm.openHistory}},[_c('img',{staticClass:"action-btn",attrs:{"src":require(
              `@/assets/images/PGSlot_Lobby_UI/horizontal/Ambslot_Lobby_Button_Icon_History.png`
            ),"alt":""}})]),_c('span',{staticClass:"button-text refhistory text"},[_vm._v(_vm._s(_vm.$t('bar.history')))])])]),(_vm.closeDeposit)?_c('div',_vm._l((_vm.modalList),function(item,index){return _c('div',{key:index,staticClass:"auto-modal d-flex flex-column align-items-center"},[_c('div',{staticClass:"warpper-btn",on:{"click":function($event){_vm.currentModal = item.mode}}},[_c('img',{staticClass:"action-btn",attrs:{"src":require(
                `@/assets/images/PGSlot_Lobby_UI/horizontal/Ambslot_Lobby_${item.mode}.png`
              ),"alt":""}})]),_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),0):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"warpper-btn",on:{"click":_vm.exitGame}},[_c('img',{staticClass:"action-btn",attrs:{"src":require(
              `@/assets/images/PGSlot_Lobby_UI/horizontal/Ambslot_Lobby_Button_Icon_Logout.png`
            ),"alt":""}})])]),_c('span',{staticClass:"button-text refhistory text"},[_vm._v(_vm._s(_vm.$t('bar.exit')))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }