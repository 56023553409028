
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RankPopUp extends Vue {
  @Prop() readonly isRankUp!: boolean;

  @Prop() readonly fontStyle!: Function;

  @Prop() readonly upEvent!: Function;

  @Prop() readonly bannerUp!: string;

  @Prop() readonly rewardrank!: number;

  @Prop() readonly rewardlevel!: number;

  @Prop() readonly level!: number;

  @Prop() readonly messagerankLevel!: string;

  @Prop() readonly messagerankGift!: string;

  @Prop() readonly rankUpDetail!: object;

  @Prop() readonly levelname!: string;

  @Prop() readonly rankCount!: number;
}
