<template>
  <div :class="`home -desktop`">
    <div v-if="!closeFeatureByGameType">
      <!-- <Bar
        class="on-Native"
        :expPercentage="expPercentage"
        :detail="detail"
        :mode="mode"
        :avatar="avatar"
        :token="token"
        :event="event"
        :lang="lang"
        :isActiveDropRate="isActiveDropRate"
        :isActiveExpRate="isActiveExpRate"
        :isDropActive="isDropActive"
        :isLevelActive="isLevelActive"
        :isRankActive="isRankActive"
        :isMentananceLevel="isMentananceLevel"
        :gotDuplicate="gotDuplicate"
        :statusDot="statusDot"
        :discount="discount"
        v-if="detail"
      /> -->
    </div>
    <ModalFrame
      class="on-Native"
      :lang="lang"
    />
    <DepositModal
      class="on-Native"
      :token="token"
      :mode="mode"
      :closeDeposit="closeDeposit"
      v-if="token"
    />
    <Game
      :onLeaveGame="onLeaveGame"
      :gameLink="gameLink"
      :backLink="backLink"
      :provider="provider"
      :token="token"
      :lobbyVersion="lobbyVersion"
      :linktoLobby="linktoLobby"
      v-if="gameLink"/>
    <EventPopUp
      :isShowEventDetail="isShowEventDetail"
      :festivalName="festivalName"
      :borderEvent="borderEvent"
      :evetDetail="evetDetail"
      :eventTextPng="eventTextPng"
      :fontStyle="fontStyle"
      :closeEvent="closeEvent"
    />
    <DropPopUp
      :dropType="dropType"
      :isDropActive="isDropActive"
      :fontStyle="fontStyle"
      :upEvent="upEvent"
      :dropBorderImg="dropBorderImg"
      :giftUrl="giftUrl"
      :dropAmount="dropAmount"
      :dropPoint="dropPoint"
      :dropMsg="dropMsg"
      :dropMsggift="dropMsggift"
      :lang="lang"
    />
    <RankPopUp
      :isRankUp="isRankUp"
      :upEvent="upEvent"
      :fontStyle="fontStyle"
      :level="level"
      :bannerUp="bannerUp"
      :rewardrank="rewardrank"
      :messagerankLevel="messagerankLevel"
      :messagerankGift="messagerankGift"
      :rankUpDetail="rankUpDetail"
      :rewardlevel="rewardlevel"
      :levelname="levelname"
      :rankCount="rankCount"
    />
    <LevelPopUp
      :isLevelUp="isLevelUp"
      :upEvent="upEvent"
      :fontStyle="fontStyle"
      :level="level"
      :bannerUp="bannerUp"
      :rewardlevel="rewardlevel"
      :rankUpDetail="rankUpDetail"
      :showLevelGif="showLevelGif"
      :messagerankLevel="messagerankLevel"
      :messagerankGift="messagerankGift"
      :levelname="levelname"
      :rankCount="rankCount"
    />
    <DuplicatePopUp
      :isDuplicate="isDuplicate"
      :exitFromDuplicate="exitFromDuplicate"
      :fontStyle="fontStyle"
      :lang="lang"
    />
    <UpdateBalance
      :isUpdateBalance="isUpdateBalance"
      :balanceMessage="balanceMessage"
      :enterToRefresh="enterToRefresh"
      :fontStyle="fontStyle"
    />
    <!-- <RotateScreen
      :isRotate="isRotate"
    /> -->
    <WrapperRanking
      :token="token"
      :lang="lang"
    />
    <Inventory
      :token="token"
      :lang="lang"
      :handleDot="handleDot"
    />
    <Point
      :token="token"
      :lang="lang"
      :discount="discount"
      :handleDot="handleDot"
    />
    <TouchFullScreen
      v-if="isFishing"
      :handleFullScreen="handleFullScreen"
    />
  </div>
</template>

<script>
import Game from '@/components/Game.vue';
// import Bar from '@/components/Bar.vue';
// import RotateScreen from '@/components/RotateScreen.vue';
import ModalFrame from '@/components/ModalFrame.vue';
import DepositModal from '@/components/DepositModal.vue';
import EventPopUp from '@/components/popup/Event.vue';
import DropPopUp from '@/components/popup/Drop.vue';
import RankPopUp from '@/components/popup/RankUp.vue';
import LevelPopUp from '@/components/popup/LevelUp.vue';
import DuplicatePopUp from '@/components/popup/DuplicateLogin.vue';
import UpdateBalance from '@/components/popup/UpdateBalance.vue';
import TouchFullScreen from '@/components/popup/TouchFullScreen.vue';
import WrapperRanking from '@/components/WrapperRanking.vue';
import Inventory from '@/views/Inventory.vue';
import Point from '@/views/PointPage.vue';
import jwt from 'jsonwebtoken';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import Deposit from '@/store/modules/Deposit';
import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import { SOCKET_URL } from '@/utils/api';
import { io } from 'socket.io-client';
import screenfull from 'screenfull';

export default {
  components: {
    Game,
    // Bar,
    ModalFrame,
    DepositModal,
    // RotateScreen,
    EventPopUp,
    DropPopUp,
    RankPopUp,
    LevelPopUp,
    DuplicatePopUp,
    UpdateBalance,
    WrapperRanking,
    Inventory,
    Point,
    TouchFullScreen,
  },
  data() {
    return {
      DepositModule: {},
      GeneralControlModule: {},
      detail: {},
      rankUpDetail: {},
      event: {},
      setTimeOut: 10000,
      expPercentage: 0,
      level: 0,
      rewardrank: 0,
      rewardlevel: 0,
      isDuplicate: false,
      // isRotate: false,
      isUpdateBalance: false,
      isShowEventDetail: false,
      isActiveDropRate: false,
      isActiveExpRate: false,
      isDropActive: false,
      isLevelActive: false,
      isRankActive: false,
      isMentananceLevel: false,
      isRankUp: false,
      isLevelUp: false,
      closeFeatureByGameType: false,
      userToken: '',
      gameLink: '',
      provider: '',
      backLink: '',
      token: '',
      prefix: '',
      mode: '',
      links: '',
      dropType: '',
      dropMsg: '',
      dropAmount: '',
      dropPoint: '',
      dropMsggift: '',
      dropImg: '',
      dropBorderImg: '',
      lobbyVersion: '',
      avatar: '',
      lang: '',
      messagerankLevel: '',
      messagerankGift: '',
      duplicateMessage: '',
      giftUrl: '',
      evetDetail: '',
      eventTextPng: '',
      balanceMessage: '',
      borderEvent: '',
      checkBannerEvent: '',
      eventName: '',
      bannerUp: '',
      festivalName: '',
      linktoLobby: '',
      gameType: '',
      ioConnect: {},
      closeDeposit: true,
      statusDot: false,
      isFishing: false,
      levelname: '',
      rankCount: '',
      counti: '',
      showLevelGif: false,
      discount: 0,
    };
  },
  async beforeCreate() {
    const getTokenByUrl = window.location.search.split('?link=');
    const DecodeWrapperToken = jwt.verify(getTokenByUrl[1], 'wrong-secret');
    const userToken = DecodeWrapperToken.token;
    this.axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    this.ioConnect = await io(SOCKET_URL, {
      auth: { token: `Bearer ${userToken}` },
      transports: ['websocket'],
    });
    this.ioConnect.on('connect_error', (error) => {
      if (error.data.type === 'UnauthorizedError') {
        console.log('User token has expired');
      }
    });
    this.ioConnect.on('Authenticated', async (res) => {
      this.isMentananceLevel = res.data.detail.systemDetail.isLevel;
      this.isDropActive = res.data.detail.agentLobbyDetail.isDrop;
      this.isLevelActive = res.data.detail.agentLobbyDetail.isLevel;
      this.isRankActive = res.data.detail.agentLobbyDetail.isRank;
      this.festivalName = res.data.detail.event.nameText[this.lang];
      this.bannerUp = res.data.detail.event.borderImg.level;
      this.checkBannerEvent = res.data.detail.event.name;
      this.borderEvent = res.data.detail.event.borderImg.notiyEvent;
      this.event = res.data.detail.event;
      this.isActiveDropRate = res.data.detail.rateDropItem;
      this.isActiveExpRate = res.data.detail.rateExp;
      this.avatar = res.data.detail.imgAvatar;
      this.discount = res.data.detail.promotion.store.discount;
      this.$store.dispatch('setProfile', res.data.detail);
      this.calDetail(res.data.detail);
      this.onEnterGame(this.ioConnect);
      this.socketOn(this.ioConnect);
    });
    this.ioConnect.on('invalidToken', (error) => {
      if (error.status.code === 10020) {
        this.gotDuplicate(1002);
      }
    });
    this.axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    const eventDetail = await this.GeneralControlModule.getEventDetail({ language: this.lang });
    if (eventDetail.data.isActive) {
      this.setEventLocalStorage(eventDetail);
    }
  },
  async created() {
    this.DepositModule = getModule(Deposit, this.$store);
    this.GeneralControlModule = getModule(GeneralControl, this.$store);
    // window.addEventListener('resize', this.onRotate);
    this.link = this.getParameterByName('link');
    const {
      link,
      provider,
      gameName,
      mode,
      token,
      lang,
      backLink,
      gameType,
      prefix,
    } = jwt.verify(this.link, 'wrong-secret');
    this.mode = mode;
    this.prefix = prefix;
    this.provider = provider;
    this.token = token;
    this.lang = lang;
    this.linktoLobby = backLink;
    this.gameLink = link;
    this.gameType = gameType;
    this.handleFishing(gameType, provider);
    this.$i18n.locale = this.lang;
    const titleName = document.getElementsByTagName('title');
    titleName[0].text = gameName[lang] || gameName.en;
    this.backLink = this.getParameterByName('from', link);
    const userForFill = jwtDecode(this.token);
    await this.DepositModule.getProfile(userForFill);
    const version = await this.GeneralControlModule.getVersion();
    this.lobbyVersion = version[this.mode];
    const depositActiveList = await this.DepositModule.verrifyPayment();
    if (depositActiveList === 1002) {
      this.closeDeposit = false;
    } else {
      this.filterDeposit = depositActiveList.filter((v) => v.isActive);
      if (!this.filterDeposit.length) {
        this.closeDeposit = false;
      }
    }
    const getBackLink = await this.GeneralControlModule.getBackLink();
    const backLikeProvider = getBackLink.find((v) => v.provider === this.provider).backLink;
    if (backLikeProvider !== '') {
      this.backLink = this.getParameterByName(backLikeProvider, link);
      if (this.backLink === 'op' || backLikeProvider === 'lobbyURL' || backLikeProvider === 'backurl' || backLikeProvider === 'backLink') {
        this.backLink = `${this.lobbyVersion}${this.token}`;
      }
      this.gameLink = link;
    }
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)
          && ['FISHING'].includes(this.gameType)
            && ['DRG'].includes(this.provider)) {
          if (screenfull.isFullscreen) {
            this.isFishing = false;
          } else {
            this.isFishing = true;
          }
        }
      });
    }
    window.addEventListener('keyup', (event) => {
      if (event.code === 'Space' && this.dropType) {
        this.upEvent('drop');
      } else if (event.code === 'Space' && this.isRankUp) {
        this.upEvent('rank');
      } else if (event.code === 'Space' && this.isLevelUp) {
        this.upEvent('level');
      }
    });
  },
  // destroyed() {
  //   window.removeEventListener('resize', this.onRotate);
  // },
  methods: {
    handleFishing(gameType, provider) {
      if (['FISHING', 'fishing'].includes(gameType) && ['SPADEGAMING', 'SLOTXO', 'DRG', 'AMBSLOT', 'ASKMEBET'].includes(provider)) {
        this.closeFeatureByGameType = true;
        if (/Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          if (['DRG', 'AMBSLOT'].includes(provider)) {
            this.isFishing = true;
          }
        }
        if (/iPod|iPad|iPhone /i.test(navigator.userAgent)) {
          if (['AMBSLOT'].includes(provider)) {
            window.location.href = (this.gameLink);
          }
        }
      }
    },
    socketOn(socket) {
      socket.on('updateRankLevel', (data) => {
        const res = JSON.parse(Buffer.from(data.data, 'base64').toString());
        this.isMentananceLevel = res.data.detail.systemDetail.islevel;
        this.isDropActive = res.data.detail.agentLobbyDetail.isDrop;
        this.isLevelActive = res.data.detail.agentLobbyDetail.isLevel;
        this.isRankActive = res.data.detail.agentLobbyDetail.isRank;
        if (res.data.detail.isLevelUp && res.data.detail.rankCount === this.detail.rankCount) {
          this.isLevelUp = true;
          this.isRankUp = false;
          this.rankUpDetail.rankCount = res.data.detail.rankCount;
          this.rankUpDetail.name = res.data.detail.name;
          this.level = res.data.detail.level;
          this.levelname = res.data.detail.name;
          this.rankCount = this.convertRankCount(res.data.detail.rankCount);
          this.showLevelGif = true;
          window.focus();
          if (res.data.detail.messageLevel && res.data.detail.messageGift !== '') {
            this.messagerankLevel = res.data.detail.messageLevel;
            this.messagerankGift = res.data.detail.messageGift;
            this.level = res.data.detail.level;
            window.focus();
          }
          setTimeout(() => {
            this.isLevelUp = false;
            document.getElementById('game').contentWindow.focus();
          }, this.setTimeOut);
        }
        if (res.data.detail.isLevelUp && res.data.detail.rankCount !== this.detail.rankCount) {
          this.rankUpDetail.rankCount = res.data.detail.rankCount;
          this.rankUpDetail.name = res.data.detail.name;
          this.isRankUp = true;
          this.isLevelUp = false;
          this.level = res.data.detail.level;
          this.levelname = res.data.detail.name;
          this.rankCount = this.convertRankCount(res.data.detail.rankCount);
          window.focus();
          if (res.data.detail.messageLevel && res.data.detail.messageGift !== '') {
            this.messagerankLevel = res.data.detail.messageLevel;
            this.messagerankGift = res.data.detail.messageGift;
            window.focus();
          }
          setTimeout(() => {
            this.isRankUp = false;
            document.getElementById('game').contentWindow.focus();
          }, this.setTimeOut);
        }
        if (res.data.detail.rewardClassUp || res.data.detail.rewardLevelUp !== 0) {
          this.rewardrank = res.data.detail.rewardClassUp;
          this.rewardlevel = res.data.detail.rewardLevelUp;
          window.focus();
        }
        this.calDetail(res.data.detail);
      });
      socket.on('dropGift', (data) => {
        const res = JSON.parse(Buffer.from(data.data, 'base64').toString());
        this.dropType = res.data.name;
        this.dropMsg = res.data.message;
        this.dropAmount = res.data.amount;
        this.dropPoint = res.data.points;
        this.dropMsggift = res.data.messageGift;
        this.dropBorderImg = res.data.borderImg;
        this.giftUrl = res.data.imgUrl;
        window.focus();
        setTimeout(() => {
          this.dropType = '';
          document.getElementById('game').contentWindow.focus();
        }, this.setTimeOut);
      });
      socket.on('disconnectTrigger', (res) => {
        const data = JSON.parse(Buffer.from(res.data, 'base64').toString());
        this.duplicateMessage = data.status.message;
        if (data.status.code === 1002) {
          this.isDuplicate = true;
        }
      });
      socket.on('updatePlayerBalance', (res) => {
        const data = JSON.parse(Buffer.from(res.data, 'base64').toString());
        this.isUpdateBalance = true;
        this.balanceMessage = data.data.message.replace(/(\\r)*\n/g, '<br />');
      });
      socket.on('disconnect', (res) => {
        console.log('disconnect', res);
      });
    },
    async setEventLocalStorage(event) {
      const eventBanner = localStorage.getItem('eventBanner');
      if (!eventBanner) {
        localStorage.setItem('eventName', this.checkBannerEvent);
        this.eventName = localStorage.getItem('eventName');
      }
      if (this.eventName === this.checkBannerEvent) {
        const eventShow = localStorage.getItem('countEvent');
        if (eventShow < 1) {
          localStorage.setItem('countEvent', 1);
        }
        if (eventShow >= 1) {
          if (Number(eventShow) === 50) {
            localStorage.setItem('countEvent', 1);
          } else {
            localStorage.setItem('countEvent', Number(eventShow) + 1);
          }
        }
        if (eventShow < 5) {
          if (event.status.code === 0) {
            this.evetDetail = event.data.text.replace(/(\\r)*\\n/g, '<br />');
            this.eventTextPng = event.data.textPng[this.lang];
            if (this.evetDetail) {
              this.isShowEventDetail = true;
            }
            setTimeout(() => {
              this.isShowEventDetail = false;
            }, this.setTimeOut);
          }
        }
      }
    },
    // onRotate() {
    //   if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     const orientation = window.screen.orientation.type;
    //     if (this.provider === 'DRG' && this.mode === 'mobile') {
    //       if (orientation === 'landscape-primary') {
    //         this.isRotate = true;
    //       } else {
    //         this.isRotate = false;
    //       }
    //     }
    //   }
    // },
    calDetail(detail) {
      this.detail = detail;
      const currentExpAmount = detail.nowExp - detail.startExp;
      const currentExpLength = detail.endExp - detail.startExp;
      this.expPercentage = (currentExpAmount / currentExpLength) * 100;
    },
    onLeaveGame() {
      this.ioConnect.emit('leaveRoom', { prefix: this.prefix }, (res) => {
        console.log('leaveRoom', res);
      });
    },
    onEnterGame(socket) {
      socket.emit('enterRoom', { prefix: this.prefix }, (res) => {
        console.log('enterRoom', res);
      });
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      const replace = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${replace}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    convertRankCount(rankCount) {
      if (rankCount === 1) return 'I';
      if (rankCount === 2) return 'II';
      if (rankCount === 3) return 'III';
      return '';
    },
    upEvent(type) {
      if (type === 'rank') {
        this.isRankUp = false;
        document.getElementById('game').contentWindow.focus();
      } else if (type === 'drop') {
        this.dropType = '';
        document.getElementById('game').contentWindow.focus();
      } else {
        this.isLevelUp = false;
        document.getElementById('game').contentWindow.focus();
      }
    },
    fontStyle() {
      if (this.lang === 'th') {
        return 'thai-font';
      // eslint-disable-next-line no-else-return
      } else {
        return 'eng-font';
      }
    },
    closeEvent() {
      this.isShowEventDetail = false;
    },
    gotDuplicate(code) {
      if (code === 1002) {
        this.isDuplicate = true;
      }
    },
    async exitFromDuplicate() {
      delete Vue.axios.defaults.headers.common.Authorization;
      const version = await this.GeneralControlModule.getVersion();
      const url = version[`${this.mode}Login`];
      window.location.href = url;
    },
    async enterToRefresh() {
      this.$router.go(0);
    },
    handleDot(event) {
      if (event !== 0) {
        this.statusDot = true;
      } else {
        this.statusDot = false;
      }
    },
    handleFullScreen() {
      console.log('Go Full');
      document.documentElement.requestFullscreen();
      this.isFishing = false;
    },
  },
  watch: {
    handleFS(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.on-Native {
  @media screen and (max-width: 300px){
    display: none;
  }
}

.handleDSFishing {
  position: absolute;
  top: -50%;
  left: -50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
}
</style>
