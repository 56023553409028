
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import ItemControl from '@/store/modules/Item';
import itemPopup from '@/components/popup/itemPopup.vue';
import moment from 'moment';

@Component({
  components: {
    itemPopup,
  },
})
export default class Inventory extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly lang: string;

  @Prop() readonly handleDot: Function;

  @Prop() readonly active: Function;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  ItemControlModule = getModule(ItemControl, this.$store);

  activeList = false;

  textRecive = '';

  reciveItem = false;

  selectList = 'inventory.dropdown.all';

  itemList = [];

  allItem = [];

  emptyItem = false;

  errorItem = false;

  errorText = '';

  isSelectItem = false;

  showItem = {
    id: '',
    name: '',
    discription: [],
    image: '',
    endTime: '',
    expDate: {
      HH: 0,
      day: 0,
      mm: 0,
    },
  };

  list = [
    { menu: 'inventory.dropdown.all', mode: 'all' },
    { menu: 'inventory.dropdown.money', mode: 'money' },
    { menu: 'inventory.dropdown.spacial', mode: 'spacial' },
  ];

  handleList(list: string, mode: string) {
    this.selectList = list;
    this.activeList = !this.activeList;
    if (mode !== 'all') {
      const filterMode = this.allItem.filter((item: any) => item.gift.type === mode);
      this.itemList = filterMode;
    } else {
      this.itemList = this.allItem;
    }
  }

  async created() {
    this.fetchItem();
  }

  handleItem(item: any) {
    this.isSelectItem = true;
    this.showItem = {
      // eslint-disable-next-line
      id: item._id,
      name: item.gift.name,
      discription: item.gift.message[this.lang].replace(/(\\r)*\n/g, '<br />'),
      image: item.image.normal,
      endTime: moment(item.endTime).format('YYYY/MM/DD, h:mm:ss a'),
      expDate: {
        HH: item.exp.HH,
        day: item.exp.day,
        mm: item.exp.mm,
      },
    };
  }

  async fetchItem() {
    const item = await this.ItemControlModule.fetchItem(this.token);
    this.allItem = item.data.map((items: any) => ({
      ...items,
      endTime: moment(items.endTime).format('YYYY/MM/DD, h:mm:ss a'),
    }));
    this.itemList = item.data.map((items: any) => ({
      ...items,
      endTime: moment(items.endTime).format('YYYY/MM/DD, h:mm:ss a'),
    }));
    const show = item.data[0];
    if (!show) this.emptyItem = true;
    this.showItem = {
      // eslint-disable-next-line
      id: show._id,
      name: show.gift.name,
      discription: show.gift.message,
      image: show.image.normal,
      endTime: moment(show.endTime).format('YYYY/MM/DD'),
      expDate: {
        HH: show.exp.HH,
        day: show.exp.day,
        mm: show.exp.mm,
      },
    };
  }

  async activeItem(id: any) {
    const param: any = {
      questId: [id],
    };
    const textItem = await this.ItemControlModule.activeItem(param);
    this.isSelectItem = false;
    if (textItem.code === 100) {
      this.errorItem = true;
      this.errorText = textItem.message;
    } else if (textItem.status.code === 0) {
      this.textRecive = textItem.data.reward.message;
      this.reciveItem = true;
      setTimeout(() => {
        this.reciveItem = false;
      }, 2000);
      this.fetchItem();
    }
  }

  handleExpItem() {
    this.errorItem = false;
    this.fetchItem();
  }

  closeInventory() {
    this.GeneralControlModule.updateInventoryModalState(false);
    this.handleDot(this.allItem.length);
  }

  closeItemPopup() {
    this.isSelectItem = false;
  }
}
