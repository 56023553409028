
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import HistoryInstance from '@/store/modules/History';
import History from '@/components/History.vue';
import WinLose from '@/components/WinLose.vue';
import WinLoseLog from '@/components/WinLoseLog.vue';

interface Item {
  date: string;
  type: string;
}

@Component({
  components: {
    History,
    WinLose,
    WinLoseLog,
  },
})
export default class ModalFramePage extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  switchMode = false;

  modalType = 'winlose';

  isLog = false;

  today = this.$moment().format('YYYY-MM-DD');

  // eslint-disable-next-line
  token: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lang: any;

  async created() {
    this.token = this.$route.query.t;
    this.lang = this.$route.query.lang;
    this.$i18n.locale = this.lang;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    await this.HistoryModule.fetchWinLose();
  }

  switchForm() {
    this.isLog = false;
    if (this.switchMode) {
      this.HistoryModule.fetchHistoryPoint();
    } else {
      this.HistoryModule.fetchWinLose();
    }
  }

  // closeModal() {
  //   this.modalType = 'history';
  //   this.GeneralControlModule.updateModalState(false);
  //   this.isLog = false;
  // }

  openLog(item: Item) {
    this.isLog = true;
    const params = {
      type: 'BET',
      date: item.date,
    };
    this.HistoryModule.fetchHistory(params);
  }

  changePeriod(item: string) {
    const params = {
      time: item,
      date: this.today,
    };
    this.HistoryModule.fetchHistory(params);
  }
}
