
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';

@Component
export default class Auto extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  @Prop() readonly token!: string;

  isBonus = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code: any

  error = '';

  async created() {
    const autoProfile = await this.DepositModule.fetchAutoProfile(this.token);
    if (autoProfile.bonus === 'BONUS') {
      this.isBonus = true;
    }
  }

  async switchBonus() {
    const { isBonus } = this;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await this.DepositModule.setAutoBonus({
      isBonus,
    });
    this.error = '';
    this.code = res.code;
    if (this.code !== 0) {
      this.isBonus = false;
      this.error = res.message;
    }
  }
}
