
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import History from '@/components/History.vue';
import HistoryInstance from '@/store/modules/History';

@Component({
  components: {
    History,
  },
})
export default class HistoryPage extends Vue {
  HistoryModule = getModule(HistoryInstance, this.$store);

  today = this.$moment().format('YYYY-MM-DD');

  created() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.$route.query.t}`;
    this.HistoryModule.fetchHistory({ time: 'TODAY', date: this.$moment() });
  }

  changePeriod(item: string) {
    const params = {
      time: item,
      date: this.today,
    };
    this.HistoryModule.fetchHistory(params);
  }
}
