
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class Game extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  @Prop() private gameLink!: string;

  @Prop() readonly backLink!: string;

  @Prop() readonly onLeaveGame!: Function;

  @Prop() readonly provider!: string;

  @Prop() readonly token!: string;

  @Prop() readonly lobbyVersion!: string;

  @Prop() readonly linktoLobby!: string;

  count = 0;

  change = false;

  load() {
    this.count += 1;
    if (this.count > 1) {
      if (!['SPADEGAMING', 'SPINIX', 'MICROGAMING'].includes(this.provider)) {
        window.location.href = this.backLink;
      } else {
        this.handleSeamless();
      }
      this.GeneralControlModule.updateIsHideBar(true);
      this.onLeaveGame();
      this.change = true;
    }
  }

  handleSeamless() {
    if (!this.linktoLobby) {
      window.location.href = `${this.lobbyVersion}${this.token}`;
    } else {
      window.location.href = this.linktoLobby;
    }
  }
}
