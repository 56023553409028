import {
  VuexModule, Module, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_CASHBACK,
  FETCH_DETAIL_CASHBACK,
  GET_CASHBACK,
} from '@/utils/api';

@Module({ name: 'Cashback' })
export default class Cashback extends VuexModule {
  @Action
  getCashback() {
    return Vue.axios({
      url: BASE_URL + GET_CASHBACK,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  getDetailCashback() {
    return Vue.axios({
      url: BASE_URL + FETCH_DETAIL_CASHBACK,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  fecthCashback() {
    return Vue.axios({
      url: BASE_URL + FETCH_CASHBACK,
      method: M_POST,
    })
      .then(({ data }) => data)
      .catch((err: string) => err);
  }
}
