var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropType && _vm.isDropActive),expression:"dropType && isDropActive"}],staticClass:"gift-wrapper upper-wrapper-rank",on:{"keypress":function($event){return _vm.upEvent('drop')}}},[_c('div',{staticClass:"wrapper-level-banner scale-wrapper"},[_c('div',{staticClass:"bg-level"},[_c('v-lazy-image',{staticClass:"asset-bg-lv",attrs:{"src":require(
           `@/assets/images/AMBSlot_lobby_popup_drop_items.png`
         )}})],1),_c('div',{staticClass:"levelup-header"},[_c('v-lazy-image',{attrs:{"src":require(
           `@/assets/images/AMBSlot_lobby_text_Congrats.png`
         )}})],1),_c('div',{staticClass:"levelgif-bg"},[_c('v-lazy-image',{staticClass:"asset-item-drop",attrs:{"src":_vm.giftUrl}})],1),_c('div',{staticClass:"level-reward eng-font"},[_c('span',{staticClass:"coin-drop mr-3"},[_c('v-lazy-image',{staticClass:"diamond-size",attrs:{"src":require(
             `@/assets/images/AMBSlot_lobby_Icon/ambslot_lobby_profile_diamond.png`
           )}}),_vm._v(" "+_vm._s(_vm.dropPoint)+" ")],1),_c('span',{staticClass:"coin-drop"},[_c('v-lazy-image',{staticClass:"diamond-size",attrs:{"src":require(
             `@/assets/images/AMBSlot_lobby_Icon/AMBSlot_lobby_icon_coin.png`
           )}}),_vm._v(" "+_vm._s(_vm.dropAmount)+" ")],1)]),_c('div',{staticClass:"wrapper-detail-drop"},[_c('div',{staticClass:"message-recive"},[_vm._v(" "+_vm._s(_vm.$t('inventory.received'))+" ")]),_c('div',{staticClass:"message-recive-item-detail eng-font"},[_vm._v(" "+_vm._s(_vm.dropMsg)+" ")]),_c('div',{staticClass:"message-recive-item eng-font",domProps:{"innerHTML":_vm._s(_vm.dropMsggift)}})]),_c('div',{staticClass:"btn-close-banner",on:{"click":function($event){return _vm.upEvent('drop')}}},[_c('div',{staticClass:"border-close-banner"},[_c('v-lazy-image',{staticClass:"btn-banner",attrs:{"src":require(
           `@/assets/images/ambslot_lobby_button_green.png`
         )}}),_c('div',{staticClass:"text-close-banner"},[_vm._v(" "+_vm._s(_vm.$t('bar.yes'))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }