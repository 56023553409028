
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import HistoryInstance from '@/store/modules/History';

@Component
export default class WinLose extends Vue {
  HistoryModule = getModule(HistoryInstance, this.$store);

  sumCurrency(val: number, rate: number) {
    const rateCurrency = rate || 1;
    return val * rateCurrency;
  }

  color(val: number) {
    let color = '';
    if (val > 0) {
      color = 'greentext';
    } else if (val < 0) {
      color = 'redtext';
    } else {
      color = 'white-text';
    }
    return color;
  }

  colorBalance(val: number) {
    let color = '';
    if (val !== 0) {
      color = 'white-text';
    }
    return color;
  }
}
