var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper flex-column justify-content-center",class:{ '-hide': !_vm.GeneralControlModule.rankingModalState }},[_c('div',{staticClass:"scale-wrapper-rank"},[_c('div',{staticClass:"close-group",on:{"click":_vm.closeRanking}},[_c('img',{staticClass:"icon",attrs:{"src":require(`@/assets/images/ambslot_lobby_button_close.png`),"alt":""}}),_c('img',{staticClass:"icon-close",attrs:{"src":require(`@/assets/images/Ambslot_Lobby_Button_Close_Icon.png`),"alt":""}})]),_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"wrapper-main"},[_c('div',{staticClass:"frame"},[_c('img',{staticClass:"frame-img",attrs:{"src":require(`@/assets/images/AMBSlot_Lobby_Frame.png`),"alt":"frame"}})]),_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-img",attrs:{"src":require(`@/assets/images/ambslot_lobby_header.png`),"alt":"header"}})]),_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-line",attrs:{"src":require(`@/assets/images/AMBSlot_lobby_Line/ambslot_lobby_header.png`),"alt":"header"}})]),_c('div',{staticClass:"header",on:{"click":function($event){return _vm.handleMenu('level', true)}}},[_c('div',{staticClass:"header-firstmenu"},[_c('img',{staticClass:"header-menuimg",attrs:{"src":require(
              `@/assets/images/AMBSlot_lobby_Icon/AMBslot_lobby_Icon_top_level.png`
              ),"alt":""}}),_c('img',{staticClass:"header-menuimgbg",attrs:{"src":require(
              `@/assets/images/ambslot_lobby_button_menu_big.png`
              ),"alt":""}}),_c('div',{class:`header-text-first-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.$t('ranking.top_level'))+" ")])])]),_c('div',{staticClass:"header",on:{"click":function($event){return _vm.handleMenu('turnover', true)}}},[_c('div',{staticClass:"header-secondmenu"},[_c('img',{staticClass:"header-menuimg",attrs:{"src":require(
              `@/assets/images/AMBSlot_lobby_Icon/AMBslot_lobby_icon_most_tumover.png`
              ),"alt":""}}),_c('img',{staticClass:"header-menuimgbg",attrs:{"src":require(
              `@/assets/images/ambslot_lobby_button_menu_big.png`
              ),"alt":""}}),_c('div',{class:`header-text-second-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.$t('ranking.most_turn_over'))+" ")])])]),_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-thirdmenu",on:{"click":function($event){return _vm.handleMenu('multiplier', true)}}},[_c('img',{staticClass:"header-menuimg",attrs:{"src":require(
              `@/assets/images/AMBSlot_lobby_Icon/AMBslot_lobby_icon_high_winniing.png`
              ),"alt":""}}),_c('img',{staticClass:"header-menuimgbg",attrs:{"src":require(
              `@/assets/images/ambslot_lobby_button_menu_big.png`
              ),"alt":""}}),_c('div',{class:`header-text-third-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.$t('ranking.high_winning'))+" ")])])]),_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-fourmenu",on:{"click":function($event){return _vm.handleMenu('winlose', true)}}},[_c('img',{staticClass:"header-menuimg-four",attrs:{"src":require(
              `@/assets/images/AMBSlot_lobby_Icon/AMBslot_lobby_icon_most_winning.png`
              ),"alt":""}}),_c('img',{staticClass:"header-menuimgbg",attrs:{"src":require(
              `@/assets/images/ambslot_lobby_button_menu_big.png`
              ),"alt":""}}),_c('div',{class:`header-text-four-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.$t('ranking.most_winning'))+" ")])])])]),_c('div',{staticClass:"content-ranking"},[_c('RankingInGame',{staticClass:"content-rank",attrs:{"type":_vm.type,"token":_vm.token}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }