
import {
  Vue, Prop, Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';

@Component({})
export default class PointPage extends Vue {
  @Prop() readonly isShowPointPage!: boolean;

  @Prop() readonly itemPoint!: any;

  @Prop() readonly lang!: string;

  @Prop() readonly discount: number;

  @Prop() readonly closePopup: Function;

  @Prop() readonly usePoint: Function;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  numberWithCommas(val: number) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
