import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_HISTORY,
  GET_HISTORY,
  FETCH_HISTORY_POINT,
} from '@/utils/api';

@Module({ name: 'History' })
export default class History extends VuexModule {
  historyList: [] = [];

  winloseList: [] = [];

  historyPointList: [] = [];

  @Mutation
  mutateHistory(historyList: []) {
    this.historyList = historyList;
  }

  @Action({ commit: 'mutateHistory' })
  async fetchHistory(param: object) {
    return Vue.axios({
      url: BASE_URL + FETCH_HISTORY,
      data: param,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          const historyList = data.data;
          return historyList;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateWinLose(winloseList: []) {
    this.winloseList = winloseList;
  }

  @Action({ commit: 'mutateWinLose' })
  async fetchWinLose() {
    return Vue.axios({
      url: BASE_URL + GET_HISTORY,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status.code;
        } else {
          const winloseList = data.data;
          return winloseList;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateHistoryPointList(historyPointList: []) {
    this.historyPointList = historyPointList;
  }

  @Action({ commit: 'mutateHistoryPointList' })
  async fetchHistoryPoint() {
    return Vue.axios({
      url: BASE_URL + FETCH_HISTORY_POINT,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status.code;
        } else {
          const historyPointList = data.data;
          return historyPointList;
        }
      })
      .catch((err: string) => err);
  }
}
