import {
  VuexModule, Module, MutationAction, Action, Mutation,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  // M_GET,
  GET_BACK_LINK,
  GET_VERSION,
  FETCH_VERSION_NEW_LOBBY,
  GET_EVENT_DETAIL,
} from '@/utils/api';

interface IUserProfile {
  avatar: number;
  endExp: number;
  isLevelUp: boolean;
  level: number;
  name: string;
  nowExp: number;
  rankCount: number;
  rateDropItem: number;
  rateExp: number;
  star: number;
  startExp: number;
  username: string;
}

interface IProviderProfile {
  backLink: string;
  provider: string;
}

interface ILobbyUrl {
  desktop: string;
  mobile: string;
}

@Module({ name: 'GeneralControl' })
export default class GeneralControl extends VuexModule {
  modalState = false;

  exitModal = false;

  depositModalState = false;

  isHideBar = false;

  rankingModalState = false;

  inventoryModalState = false;

  pointModalState = false;

  userProfile: IUserProfile;

  backLinkList: IProviderProfile;

  lobbyUrl: ILobbyUrl;

  @MutationAction
  async updateModalState(modalState: boolean) {
    return {
      modalState,
    };
  }

  @MutationAction
  async updateDepositModalState(depositModalState: boolean) {
    return {
      depositModalState,
    };
  }

  @MutationAction
  async updateRankingModalState(rankingModalState: boolean) {
    return {
      rankingModalState,
    };
  }

  @MutationAction
  async updatePointModalState(pointModalState: boolean) {
    return {
      pointModalState,
    };
  }

  @MutationAction
  async updateInventoryModalState(inventoryModalState: boolean) {
    return {
      inventoryModalState,
    };
  }

  @MutationAction
  async updateIsHideBar(isHideBar: boolean) {
    return {
      isHideBar,
    };
  }

  @MutationAction
  async updateExitModal(exitModal: boolean) {
    return {
      exitModal,
    };
  }

  @Mutation
  mutateSetProfile(userProfile: IUserProfile) {
    this.userProfile = userProfile;
  }

  @Action({ commit: 'mutateSetProfile' })
  async setProfile(userProfile: IUserProfile) {
    return userProfile;
  }

  @Mutation
  mutateGetBackLink(backLinkList: IProviderProfile) {
    this.backLinkList = backLinkList;
  }

  @Action({ commit: 'mutateGetBackLink' })
  async getBackLink() {
    return Vue.axios({
      url: BASE_URL + GET_BACK_LINK,
      method: M_POST,
      data: {},
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          const backLinkList = data.data;
          return backLinkList;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateGetLobby(LobbyUrl: ILobbyUrl) {
    this.lobbyUrl = LobbyUrl;
  }

  @Action({ commit: 'mutateGetLobby' })
  async getVersion() {
    return Vue.axios({
      url: BASE_URL + GET_VERSION,
      method: M_POST,
      data: {},
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw code;
        } else {
          return data.data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  async getVersionLobby() {
    return Vue.axios({
      url: BASE_URL + FETCH_VERSION_NEW_LOBBY,
      method: M_POST,
      data: {},
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data.data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  async getEventDetail(lang: any) {
    return Vue.axios({
      url: BASE_URL + GET_EVENT_DETAIL,
      method: M_POST,
      data: lang,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }
}
