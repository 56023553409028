
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import PointInstance from '@/store/modules/Point';
import ProfileInstance from '@/store/modules/Profile';
import ConfirmPoint from '@/components/popup/ConfirmPoint.vue';
import PointActive from '@/components/popup/PointActive.vue';

@Component({
  components: {
    ConfirmPoint,
    PointActive,
  },
})
export default class PointPage extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly lang: string;

  @Prop() readonly discount: number;

  @Prop() readonly handleDot: Function;

  @Prop() readonly active: Function;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  PointModule = getModule(PointInstance, this.$store);

  ProfileModule = getModule(ProfileInstance, this.$store);

  activeList = false;

  isShowPointPage = false;

  isShowAlertPoint = false;

  dataPoint = {};

  dataStatus = {};

  itemPoint = [];

  pointList = [];

  profile = [];

  created() {
    this.fetch();
  }

  async fetch() {
    this.profile = await this.ProfileModule.fetchProfile();
    this.pointList = await this.PointModule.fetchPoint();
  }

  activePoint(val: never[]) {
    this.itemPoint = val;
    this.isShowPointPage = true;
  }

  async usePoint(val: any) {
    const pointId = val;
    const res = await this.PointModule.activePoint({ itemId: pointId });
    this.isShowAlertPoint = true;
    this.dataPoint = res;
  }

  closeStoreModal() {
    this.fetch();
    this.GeneralControlModule.updatePointModalState(false);
    this.isShowPointPage = false;
    this.isShowAlertPoint = false;
  }

  closePopup() {
    this.isShowPointPage = false;
  }

  closePoint() {
    this.GeneralControlModule.updatePointModalState(false);
  }

  numberWithCommas(val: number) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
