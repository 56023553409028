
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RankingInstance from '@/store/modules/Ranking';

@Component
export default class RankInGame extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly type: string;

  RankingModule = getModule(RankingInstance, this.$store);

  lists: any = [];

  header = ''

  @Watch('type', { immediate: true, deep: true })
  handleType() {
    this.fetchRank(this.type);
    if (this.type === 'level') {
      this.header = 'ranking.top_level';
    } else if (this.type === 'turnover') {
      this.header = 'ranking.most_turn_over';
    } else if (this.type === 'multiplier') {
      this.header = 'ranking.high_winning';
    } else if (this.type === 'winlose') {
      this.header = 'ranking.most_winning';
    }
  }

  created() {
    this.fetchRank(this.type);
  }

  async fetchRank(type: string) {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    const params = {
      key: type,
    };
    const rankList = await this.RankingModule.fetchRanking(params);
    this.lists = rankList;
  }
}
