import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_RANKING,
} from '@/utils/api';

@Module({ name: 'Ranking' })
export default class Ranking extends VuexModule {
  rankingList: [] = [];

  @Mutation
  mutateRanking(rankingList: []) {
    this.rankingList = rankingList;
  }

  @Action({ commit: 'mutateRanking' })
  async fetchRanking(param: object) {
    return Vue.axios({
      url: BASE_URL + FETCH_RANKING,
      data: param,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          const rankingList = data.data;
          return rankingList;
        }
      })
      .catch((err: string) => err);
  }
}
