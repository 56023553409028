
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import HistoryInstance from '@/store/modules/History';

@Component
export default class History extends Vue {
  @Prop() readonly changePeriod!: Function;

  HistoryModule = getModule(HistoryInstance, this.$store);

  periodList = [
    'TODAY',
    'YESTERDAY',
    'WEEKLY',
  ];

  color(val: number) {
    let color = '';
    if (val > 0) {
      color = 'greentext';
    } else if (val < 0) {
      color = 'redtext';
    } else {
      color = 'whitetext';
    }
    return color;
  }

  dateTimeFormatter(unix: string | number | Date) {
    const t = new Date(unix);
    const zeroPad = (value: any, size: any): string => (String(value).length < size ? zeroPad(`0${String(value)}`, size) : value);
    return `${zeroPad(t.getFullYear(), 2)}-${zeroPad(t.getMonth() + 1, 2)}-${zeroPad(t.getDate(), 2)} ${zeroPad(t.getHours(), 2)}:${zeroPad(t.getMinutes(), 2)}:${zeroPad(t.getSeconds(), 2)}`;
  }
}
