
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EvennPopup extends Vue {
  @Prop() readonly isUpdateBalance!: boolean;

  @Prop() readonly balanceMessage!: string;

  @Prop() readonly enterToRefresh!: Function;

  @Prop() readonly fontStyle!: Function;
}
