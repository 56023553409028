
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import WinLose from '@/components/WinLose.vue';
import WinLoseLog from '@/components/WinLoseLog.vue';
import HistoryInstance from '@/store/modules/History';

interface Item {
  date: string;
}

@Component({
  components: {
    WinLose,
    WinLoseLog,
  },
})

export default class HistoryPage extends Vue {
  HistoryModule = getModule(HistoryInstance, this.$store);

  today = this.$moment().format('YYYY-MM-DD');

  isLog = false;

  created() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.$route.query.t}`;
    this.HistoryModule.fetchWinLose();
  }

  openLog(item: Item) {
    this.isLog = true;
    const params = {
      type: 'BET',
      date: item.date,
    };
    this.HistoryModule.fetchHistory(params);
  }
}
