import {
  VuexModule, Module, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_ITEM,
  ACTIVE_ITEM,
} from '@/utils/api';

@Module({ name: 'Cashback' })
export default class Item extends VuexModule {
  @Action
  fetchItem(token: string) {
    return Vue.axios({
      url: BASE_URL + FETCH_ITEM,
      data: token,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  activeItem(id: string) {
    return Vue.axios({
      url: BASE_URL + ACTIVE_ITEM,
      data: id,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }
}
