var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRankUp)?_c('div',{staticClass:"gift-wrapper upper-wrapper-rank",on:{"click":function($event){_vm.isRankUp = false},"keypress":function($event){return _vm.upEvent('rank')}}},[_c('div',{staticClass:"wrapper-level-banner scale-wrapper"},[_c('div',{staticClass:"bg-level"},[_c('v-lazy-image',{staticClass:"asset-bg-lv",attrs:{"src":require(
          `@/assets/images/AMBSlot_lobby_popup_level.png`
        )}})],1),_c('div',{staticClass:"levelup-header"},[_c('v-lazy-image',{attrs:{"src":require(
          `@/assets/images/Ranking/AMBSlot_lobby_text_Rankup.png`
        )}})],1),_c('div',{staticClass:"levelname eng-font"},[_c('span',[_vm._v(_vm._s(_vm.levelname)+" "+_vm._s(_vm.rankCount))])]),_vm._m(0),_c('div',{staticClass:"levelgif-bg"},[_c('img',{attrs:{"src":require(
          `@/assets/images/evo/${_vm.rankUpDetail.name}-${_vm.rankUpDetail.rankCount}.gif`
        ),"alt":""}})]),_c('div',{staticClass:"isLevel-up-before"},[_vm._v(" LV. "+_vm._s(_vm.level-1)+" ")]),_c('div',{staticClass:"isLevel-up-to"},[_c('v-lazy-image',{attrs:{"src":require(
          `@/assets/images/AMBSlot_lobby_icon_arrow.png`
        )}})],1),_c('div',{staticClass:"isLevel-up"},[_vm._v(" LV. "+_vm._s(_vm.level)+" ")]),_vm._m(1),_c('div',{staticClass:"level-reward eng-font"},[_c('span',{staticClass:"coin-drop mr-3"},[_c('v-lazy-image',{staticClass:"diamond-size",attrs:{"src":require(
            `@/assets/images/AMBSlot_lobby_Icon/ambslot_lobby_profile_diamond.png`
          )}}),_vm._v(" "+_vm._s(_vm.rewardlevel)+" ")],1),_c('span',{staticClass:"coin-drop"},[_c('v-lazy-image',{staticClass:"diamond-size",attrs:{"src":require(
            `@/assets/images/AMBSlot_lobby_Icon/AMBSlot_lobby_icon_coin.png`
          )}}),_vm._v(" "+_vm._s(_vm.rewardrank)+" ")],1)]),_c('div',{staticClass:"btn-close-banner",on:{"click":function($event){return _vm.upEvent('rank')}}},[_c('div',{staticClass:"border-close-banner"},[_c('v-lazy-image',{staticClass:"btn-banner",attrs:{"src":require(
          `@/assets/images/ambslot_lobby_button_green.png`
        )}}),_c('div',{staticClass:"text-close-banner"},[_vm._v(" "+_vm._s(_vm.$t('bar.yes'))+" ")])],1)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"levelgif-bg"},[_c('img',{attrs:{"src":require("@/assets/images/levelup/AMBSlot_lobby_level_up.gif"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-reward eng-font"},[_c('span',[_vm._v("REWARDS")])])
}]

export { render, staticRenderFns }